html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hotels__card:hover{
  background: #ccc;
  transition: 0.2s all;
}

.hotels__card:not(:hover){
  background: #fff;
  transition: 0.2s all;
}

.trip__header{
  background-image: url('https://img.veenaworld.com/customized-holidays/world/europe/shfr1/banner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 350px;
  overflow: unset !important;
}

.trip__main_tabs {
  border: 2px solid black;
}
.tab__list .MuiTabs-flexContainer {
  justify-content: space-evenly;
}

.css-gin7j-MuiFormLabel-root-MuiInputLabel-root {
  color: gray !important;
}
.MuiInputLabel-sizeSmall {
  color: gray !important;
}
.flights__form_tab{
  padding: 25px;
  padding-top: 35px;
  padding-bottom: 65px;
}
.flights__form_input input {
  padding: auto 15px;
}
.flight__data_card {
  max-height: 142px;
  overflow-y: auto !important;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(196, 191, 191); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(114, 108, 108); ; 
}
.lodging__filters_div {
  background-color: rgb(44, 44, 44);
}
.lodging__header {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.lodging__filters_div {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: #444 !important;
  box-shadow: none !important;
  padding: 20px 0px !important;
}
.trip__transportation__header{
  background-image: url('https://www.emtracsystems.com/wp-content/uploads/2021/06/Banner-Red-Passenger-Train-at-Station-580.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 300px;
}
.box__shadow{
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3) !important;
}
.explore__data_section {
  padding-top: 5% !important;
  background-color: #eee !important;
  z-index: 0;
}
.explore__text > span {
  font-size: 24px !important;
  font-weight: bold !important;
}
.pac-container {
  z-index: 10000 !important;
}
.slick-slide > div {
  padding: 10px;
}
.slick-slide > div > .home__slide {
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.css-1x2zs8q-MuiBadge-badge{
  background-color: orangered  !important;
}
.active{
 border-bottom: 1px solid orangered;
}
.flightFrom .css-1757k3d-MuiButtonBase-root-MuiMenuItem-root {
width: 14.5rem;
}
.arrow-pointer{  
    background-color: #728db6;
  height: 2px;
  width: 100px;
  position: relative;
  margin: 0 18px;
  display: inline-block;}
  .arrow-pointer:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 0;
    border-color: #728db6 transparent transparent transparent;
    right: -2px;
    top: -6px;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1)
  }
 .hotel__detail .slick-prev {
  left: 15px !important;
  z-index: 1000 !important;
  background-color: white !important;
border-radius: 50%;
 }
 .hotel__detail .slick-next {
  right: 15px !important;
  z-index: 1000 !important;
background-color: white !important;
border-radius: 50%;

 }
 .hotel__detail .slick-dots{
  bottom: -3px !important;
 } 